<template>
    <div>
        <Panel  :title="titleView"  style="margin:0px 1px; ">
            <Form ref="form" :model="model" style="width : 50%; ">
                <fieldset class="c-fieldset">
                    <legend class="c-legend"><b>Filter</b></legend>
                    <table width="100%" cellspacing="1" cellpadding="5" border="0">
                        <tbody>
                            <tr>
                                <td width="15%">Schedule Date</td>
                                <td width="30%">
                                    <DateBox inputId="start" name="start" class="f-field-text" v-model="model.start" format="dd/MM/yyyy"></DateBox>
                                </td>
                                <td width="5%" align="center">to</td>
                                <td width="30%">
                                    <DateBox inputId="end" name="end" class="f-field-text" v-model="model.end" format="dd/MM/yyyy"></DateBox>
                                </td>
                                <td width="5%">Status</td>
                                <td width="15%">
                                    <ComboBox  :data="status" inputId="status" name="status" class="f-field-text" v-model="model.status" @selectionChange="selectionChangeStatus($event)"></ComboBox>
                                </td>
                                <td width="5%">
                                    <LinkButton :iconCls="icon.iconSearch" @click="loadData()" style="width:80px">{{ btnText.search }}</LinkButton>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>
            </Form>
        </Panel>
        <Panel style="margin:2px 1px; ">
            <div class="datagrid-toolbar">
                <LinkButton v-if="viewGrid"  :plain="true" :iconCls="icon.iconExport" @click="onExport()">{{ btnText.export }}</LinkButton>
            </div>
            <DataGrid
                    ref="dataGrid"
                    :columnResizing="true"
                    :border="false"
                    :loading="dataGrid.loading"                                    
                    :data="dataGrid.rows"
                    :pageSize="dataGrid.pageSize"
                    :pagination="true"
                    :total="dataGrid.total"
                    >                
                <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                    <template slot="body" slot-scope="scope">
                        {{scope.rowIndex + 1}}
                    </template>
                </GridColumn>
                <GridColumn field="manifest_id" title="Manifest Id"></GridColumn>
                <GridColumn field="schedule_date" title="Schedule Date"></GridColumn>
                <GridColumn field="trip" title="Trip"></GridColumn>
                <GridColumn field="vehicle_id" title="Vehicle"></GridColumn>
                <GridColumn field="type_id" title="Vehicle Type"></GridColumn>
                <GridColumn field="vehicle_status" title="Vehicle Status"></GridColumn>
                <GridColumn field="vendor_id" title="Vendor ID"></GridColumn>
                <GridColumn field="client_name" title="Client Name"></GridColumn>
                <GridColumn field="origin_name" title="Origin Name"></GridColumn>
                <GridColumn field="origin_address" title="Origin Address"></GridColumn>
                <GridColumn field="origin_area_id" title="Origin Area"></GridColumn>
                <GridColumn field="dest_name" title="Destination Name"></GridColumn>
                <GridColumn field="dest_address" title="Destination Address"></GridColumn>
                <GridColumn field="dest_area_id" title="Destination Area"></GridColumn>
                <GridColumn field="tonnage" title="Tonnage"></GridColumn>
                <GridColumn field="invpch_reference" title="Self Billing Reference"></GridColumn>
                <GridColumn field="variable_cost" title="Amount" align="right">
                    <template slot="body" slot-scope="scope">
                        {{formatMoney(scope.row.variable_cost)}}
                    </template>
                </GridColumn>
                <GridColumn field="sum_component_cost" title="Component Cost" align="right">
                    <template slot="body" slot-scope="scope">
                        {{formatMoney(scope.row.variable_cost)}}
                    </template>
                </GridColumn>
                <!-- for dedicated transporter -->
                <GridColumn :hidden="disableDedicated" field="transporter_rate_expense_type" title="Expense Type" align="right">
                    <template slot="body" slot-scope="scope">
                        {{formatMoney(scope.row.variable_cost)}}
                    </template>
                </GridColumn>
                <GridColumn :hidden="disableDedicated" field="transporter_rate_expense_in" title="Expense In" align="right">
                    <template slot="body" slot-scope="scope">
                        {{formatMoney(scope.row.variable_cost)}}
                    </template>
                </GridColumn>
                <GridColumn :hidden="disableDedicated" field="transporter_rate_expense_out" title="Expense Out" align="right">
                    <template slot="body" slot-scope="scope">
                        {{formatMoney(scope.row.variable_cost)}}
                    </template>
                </GridColumn>
                <GridColumn :hidden="disableDedicated" field="transporter_expense_drop_charge" title="Drop Charge" align="right">
                    <template slot="body" slot-scope="scope">
                        {{formatMoney(scope.row.variable_cost)}}
                    </template>
                </GridColumn>
                <GridColumn :hidden="disableDedicated" field="transporter_expense_different_area" title="Different Area" align="right">
                    <template slot="body" slot-scope="scope">
                        {{formatMoney(scope.row.variable_cost)}}
                    </template>
                </GridColumn>
                <GridColumn field="total_amount" title="Total Amount" align="right">
                    <template slot="body" slot-scope="scope">
                        {{formatMoney(scope.row.variable_cost)}}
                    </template>
                </GridColumn>

                <GridColumn field="invsal_reference" title="Invoice Reference"></GridColumn>
                <GridColumn field="client_variable_cost" title="Client Amount" align="right">
                    <template slot="body" slot-scope="scope">
                        {{formatMoney(scope.row.variable_cost)}}
                    </template>
                </GridColumn>
                <GridColumn field="client_sum_component_cost" title="Client Component Cost" align="right">
                    <template slot="body" slot-scope="scope">
                        {{formatMoney(scope.row.variable_cost)}}
                    </template>
                </GridColumn>
                <!-- for dedicated client -->
                <GridColumn :hidden="disableDedicated" field="client_rate_expense_type" title="Client Expense Type" align="right">
                    <template slot="body" slot-scope="scope">
                        {{formatMoney(scope.row.variable_cost)}}
                    </template>
                </GridColumn>
                <GridColumn :hidden="disableDedicated" field="client_rate_expense_in" title="Client Expense In" align="right">
                    <template slot="body" slot-scope="scope">
                        {{formatMoney(scope.row.variable_cost)}}
                    </template>
                </GridColumn>
                <GridColumn :hidden="disableDedicated" field="client_rate_expense_out" title="Client Expense Out" align="right">
                    <template slot="body" slot-scope="scope">
                        {{formatMoney(scope.row.variable_cost)}}
                    </template>
                </GridColumn>
                <GridColumn :hidden="disableDedicated" field="client_expense_drop_charge" title="Client Drop Charge" align="right">
                    <template slot="body" slot-scope="scope">
                        {{formatMoney(scope.row.variable_cost)}}
                    </template>
                </GridColumn>
                <GridColumn :hidden="disableDedicated" field="client_expense_different_area" title="Client Different Area" align="right">
                    <template slot="body" slot-scope="scope">
                        {{formatMoney(scope.row.variable_cost)}}
                    </template>
                </GridColumn>
                <GridColumn field="client_total_amount" title="Client Total Amount" align="right">
                    <template slot="body" slot-scope="scope">
                        {{formatMoney(scope.row.variable_cost)}}
                    </template>
                </GridColumn>

            </DataGrid>
        </Panel>
    </div>
</template>
<script>
    import GlobalUtil from '@/utils/GlobalUtil';
    import InformationUtil from '@/utils/InformationUtil';
    import IconUtil from '@/utils/IconUtil';
    import ReportService from '@/services/ReportService';

    export default {
        name: 'PAndLView',
        data () {
            return {
                titleView : "P&L Report",
                icon : IconUtil,
                viewGrid : false,
                disableDedicated : null,
                model : {
                    start : null,
                    end : null,
                    status : 1
                },
                btnText :{
                    print : InformationUtil.printText,
                    search : InformationUtil.searchText,
                    export : InformationUtil.exportText,
                },
                dataGrid : {
                    loading: false,
                    rows : [],
                    total: 0,
                    pageSize: 10,
                    start : 0,
                    pageLayout: ['list','sep','first','prev','next','last','sep','refresh','info'],
                },
                status : [
                    {text: 'ON CALL', value: '1'},
                    {text: 'DEDICATED', value: '2'}
                ],
            }
        },
        methods: {
            async loadData(){
                this.dataGrid.loading = true;
                this.viewGrid = false;

                this.model.start = new Date(this.$moment(this.model.start).format('YYYY-MM-DD'));
                this.model.end = new Date(this.$moment(this.model.end).format('YYYY-MM-DD'));

                let param = {
                    start_date : this.model.start,
                    end_date : this.model.end,
                    status : this.model.status,
                }

                return ReportService.getData('/pnl', this.dataGrid, param)
                    .then((response) => {
                        if(response.data !== "" && response.data !== null){
                            let data = response.data; 
                            this.dataGrid.total = data.total;
                            this.dataGrid.rows = data.rows;
                        }
                        
                        this.dataGrid.loading = false;
                        this.viewGrid = this.dataGrid.rows.length > 0 ? true : false;
                    });
            },
            onExport(){

                this.model.start = new Date(this.$moment(this.model.start).format('YYYY-MM-DD'));
                this.model.end = new Date(this.$moment(this.model.end).format('YYYY-MM-DD'));

                let param = {
                    start_date : this.model.start,
                    end_date : this.model.end,
                    status : this.model.status,
                }
                ReportService.downloadFile("/pnl/export", param)
                            .then((res) => {
                                if(res.type === 'application/vnd.ms-excel' && res != null){
                                    let blob = new Blob([res], { type: "application/vnd.ms-excel" })
                                    let objectUrl = window.URL.createObjectURL(blob)
                                    const elem = window.document.createElement('a')
                                    elem.href = objectUrl;
                                    elem.download = "Report P&L_"+ this.$moment(this.model.start).format('YYYY-MM-DD') + "_"+ this.$moment(this.model.end).format('YYYY-MM-DD') +".xls"
                                    document.body.appendChild(elem)
                                    elem.click()
                                    document.body.removeChild(elem)
                                    window.URL.revokeObjectURL(blob);
                                    window.URL.revokeObjectURL(objectUrl);
                                } else {
                                    this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: res.message
                                    });
                                }
                            }).catch((error) => {
                                this.$refs.notification.error(error);
                            });
            },
            selectionChangeStatus(e){
                this.disableDedicated = e.value === '1'
            },

            formatMoney(amount){
                return GlobalUtil.formatMoney(amount, 0);
            }
        },
        created(){
            this.model.start = new Date();
            this.model.end = new Date();
            this.model.status = 1;
            GlobalUtil.removeWindow();
        }
    }
    </script>