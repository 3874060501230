export default{
    required : "Required",
    selectRow : "Please select row",
    checkedRow : "Please checked row",
    add : "Added Successfully",
    generate : "Generated Successfully",
    save : "Save successfully.",
    update : "Updated Successfully",
    delete : "Deleted Successfully",
    duplicate : "Duplicated Successfully",
    refresh :  "Data Refreshed Successfully",
    filter :  "Data Filter Successfully",
    confirmAdd : "Are you sure want to add this data?",
    confirmUpdate : "Are you sure want to update this data?",
    confirmDelete : "Are you sure want to delete this data?",
    confirmDuplicate : "Are you sure want to duplicate this data?",
    noAccess : "You don't have access to this page",
    confirmConfirmManifest : "Are you sure want to Confirm this manifest?",
    confirmUnConfirmManifest : "Are you sure want to un Confirm this manifest?",

    confirmLogout : "Are you sure want to logout?",
    confirmCancel : "Are you sure want to cancel?",
    confirmSave : "Are you sure want to save?",
    confirmSubmit : "Are you sure want to submit?",
    confirmApprove : "Are you sure want to approve?",
    confirmReject : "Are you sure want to reject?",
    confirmClose : "Are you sure want to close?",

    dataSaveSuccess : "Your data has been save successfully.",
    
    confirmSetToManifest : "Are you sure want to set to manifest?",
    confirmManifest : "Manifest has been confirm successfully.",
    confirmGenerate : "Are you sure want to generate this data?",

    clearPolygon : "Clear Polygon",
    savePolygon : "Save Polygon",

    // grid
    loadGrid : "Data loaded",
    successLoadGrid : "successfully",

    // placeholder
    searching : "Searching",
    searchHolder : "Please input value here...",

    // filter
    scheduleDate : "Schedule Date",
    trip : "Trip",
    clientId : "Client Id",

    // form
    formAddTitle : "Add Data",
    formUpdateTitle : "Update Data",
    formViewTitle : "View Data",
    formEditTitle : "Edit Data",
    formInfoTitle : "Info Data",
    formWarningTitle : "Warning Data",
    formErrorTitle : "Error Data",
    formSuccessTitle : "Success Data",
    formConfirmTitle : "Confirmation Data",
    formDeleteTitle : "Delete Data",
    formSaveTitle : "Save Data",
    formCancelTitle : "Cancel Data",
    formCloseTitle : "Close Data",
    formSubmitTitle : "Submit Data",
    formApproveTitle : "Approve Data",
    formRejectTitle : "Reject Data",

    addInvoiceManifest : "Manifest Required",


    // file
    infoSelectFile : "Please Select File",

    // info
    infoManifestConfirm : "Manifest Already Confirm",
    infoDetail : "Details Information",


    //button text
    addText : "Add",
    editText : "Edit",
    updateText : "Update",
    deleteText : "Delete",
    removeText : "Remove",
    refreshText : "Refresh",
    cancelText : "Cancel",
    saveText : "Save",
    closeText : "Close",
    okText : "Ok",
    yesText : "Yes",
    noText : "No",
    printText : "Print",
    printInvoiceText : "Print Invoice",
    searchText : "Search",
    multiSearchText : "Multiple Search",
    resetText : "Reset",
    downloadText : "Download",
    downloadTemplateText : "Download Template",
    successText : "Success",
    warningText : "Warning",
    errorText : "Error",
    infoText : "Info",
    exportText : "Export",
    importText : "Import",
    uploadText : "Upload",
    duplicateText : "Duplicate",
    generateText : "Generate",

    uploadFile : "Upload File",

    // page title
    // master
    area : "Area",
    client : "Client",
    clientRate : "Client Rate",
    customer : "Customer",
    driver : "Driver",
    mechanic : "Mechanic",
    transporter : "Transporter",
    transporterRate : "Transporter Rate",
    vehicle : "Vehicle",
    vehicleType : "Vehicle Type",
    workshop : "Workshop",
    postalCode : "Postal Code",

    // fleet view
    truckAccident : "Truck Accident",
    serviceOrder : "Service Order",
    serviceTask : "Service Task",

    // outbound view
    salesOrder : "Sales Order",
    suggestionSalesOrder : "Suggestion Sales Order",
    deliveryOrder : "Delivery Order",
    deliveryOrderItem : "Delivery Order Item",
    deliveryOrderItemDetail : "Delivery Order Item Detail",

    // transport view
    truckingOrder : "Trucking Order",
    transportOrder : "Transport Order",
    routePlanning : "Route Planning",
    manifest : "Manifest",
    trafficMonitoring : "Traffic Monitoring",
    timeWindow : "Time Window",
    pod : "Proof Of Delivery",
    selfBilling : "Self Billing",
    clientInvoice : "Client Invoice",

    // user management
    user : "User",
    role : "Role",
    userProfile : "User Profile",

    manifestRate : "Manifest Rate"
}