class GlobalUtil {
    removeWindow() {
        for(let countWindow = 0; countWindow < 4; countWindow++) {
            // delete window
            let window = document.querySelector('body').getElementsByClassName('panel f-column window window-shadow');
            for(let i = 0; i < window.length; i++) {
                window[i].remove();
            }

            // delete windowMask
            let windowMask = document.querySelector('body').getElementsByClassName('window-mask');
            for(let i = 0; i < windowMask.length; i++) {
                windowMask[i].remove();
            }
        }
    }

    formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
        try {
          decimalCount = Math.abs(decimalCount);
          decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      
          const negativeSign = amount < 0 ? "-" : "";
      
          let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
          let j = (i.length > 3) ? i.length % 3 : 0;
      
          return negativeSign +
            (j ? i.substr(0, j) + thousands : '') +
            i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
            (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
          console.log(e)
        }
    }
}

export default new GlobalUtil();